import isBoolean from 'lodash-es/isBoolean'
import mapValues from 'lodash-es/mapValues'
import { useMemo } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { CompatRoute } from 'react-router-dom-v5-compat'

import { RoleId, useCurrentUser, useFacilityFeatures } from './api'
import useAuth from './auth'
import CenterSpinner from './components/CenterSpinner'
import { useCurrentFacility } from './hooks'
import useActiveRole from './state/role'

interface RouteConfigEntry {
  isAllowed: boolean
  isInMenu: boolean
}

const DEFAULT_ROUTE_CONFIG_ENTRY = { isAllowed: false, isInMenu: true }

function useCurrentRoleRouteConfig(): { isLoading: boolean; routeConfig: { [key: string]: RouteConfigEntry } } {
  const [roleQuery] = useActiveRole()
  const facilityQuery = useCurrentFacility()
  const facilityFeatures = useFacilityFeatures(facilityQuery.facilityId).data
  const { isLoggedIn } = useAuth()

  return useMemo(() => {
    const hasOrganizations = !!roleQuery.data?.organizations?.length
    const isSignedUpForManufacturerProfile = roleQuery.data?.signUpSourceId === 'MANUFACTURER_SURVEY'
    const isSignedUpForHandlerProfile = roleQuery.data?.signUpSourceId === 'HANDLER_SURVEY'
    const isSignedUpForRecyclerProfile = roleQuery.data?.signUpSourceId === 'RECYCLER_SURVEY'

    const inputRouteConfig = {
      [RoleId.Admin]: {
        '/admin/users': true,
        '/admin/brands': true,
        '/admin/manufacturers': true,
        '/admin/handlers': true,
        '/admin/recyclers': true,
        '/admin/inventory': true,
        '/admin/shipments': true,
        '/facilities': { isAllowed: true, isInMenu: false },
        '/admin/purchase-orders': true,
        '/admin/production': true,
        '/admin/transaction-overview': true,
      },
      [RoleId.InventoryManager]: {
        '/profile': true,
        '/inventory': true,
        '/handlers': facilityQuery.isRecycler || facilityQuery.isFactory,
        '/suppliers': facilityQuery.isRecycler || facilityQuery.isHandler,
        '/suppliers-overview': facilityQuery.isRecycler || facilityQuery.isHandler,
        '/purchase-orders': facilityQuery.isFactory || (facilityQuery.isHandler && facilityFeatures?.poCreation),
        '/transaction-overview': facilityQuery.isHandler,
        '/shipments': true,
        '/recyclers': facilityQuery.isFactory || facilityQuery.isHandler,
        '/production': facilityQuery.isRecycler,
        '/statistics': true,
      },
      [RoleId.OrganizationManager]: {
        '/users': hasOrganizations,
        '/manufacturer-profile': isSignedUpForManufacturerProfile && !hasOrganizations,
        '/handler-profile': isSignedUpForHandlerProfile && !hasOrganizations,
        '/recycler-profile': isSignedUpForRecyclerProfile && !hasOrganizations,
        '/organization':
          (!isSignedUpForManufacturerProfile && !isSignedUpForHandlerProfile && !isSignedUpForRecyclerProfile) ||
          hasOrganizations,
        '/inventory/facility/:id': !isSignedUpForHandlerProfile && hasOrganizations,
        '/recyclers': isSignedUpForHandlerProfile,
        '/facilities': hasOrganizations,
        '/shipments': !isSignedUpForHandlerProfile && hasOrganizations,
        '/statistics': true,
      },
      [RoleId.BrandManager]: {
        '/brand/users': true,
        '/brand/inventory': true,
        '/manufacturers': true,
        '/recyclers': true,
        '/handlers': true,
        '/statistics': true,
      },
      [RoleId.ProjectManager]: {
        '/recyclers': true,
        '/handlers': true,
        '/manufacturers': true,
        '/project/users': true,
        '/statistics': !!roleQuery.data?.projects?.[0]?.reportLinks?.length,
      },
      [RoleId.Unknown]: {
        '/login': !isLoggedIn,
        '/sign-up/:signUpCode?': !isLoggedIn,
        '/sign-up/manufacturer-profile/:signUpCode': !isLoggedIn,
        '/sign-up/handler-profile/:signUpCode': !isLoggedIn,
        '/sign-up/recycler-profile/:signUpCode': !isLoggedIn,
      },
    }

    const roleId = roleQuery.data?.roleId ?? RoleId.Unknown
    const routeConfig =
      roleQuery.isLoading || facilityQuery.isLoading
        ? {}
        : mapValues(inputRouteConfig[roleId], (route) => {
            const normalizedConfig = isBoolean(route) ? { isAllowed: route } : route
            return Object.assign({}, DEFAULT_ROUTE_CONFIG_ENTRY, normalizedConfig)
          })
    return { isLoading: roleQuery.isLoading, routeConfig }
  }, [
    roleQuery.isLoading,
    facilityQuery.isLoading,
    roleQuery.data,
    facilityQuery.isFactory,
    facilityQuery.isHandler,
    facilityQuery.isRecycler,
    facilityFeatures,
    isLoggedIn,
  ])
}

export default function useAllowedRoutes(): { isLoading: boolean; allowedRoutes: string[] } {
  const { isLoading, routeConfig } = useCurrentRoleRouteConfig()
  const allowedRoutes = Object.entries(routeConfig)
    .filter(([, config]) => config.isAllowed)
    .map(([route]) => route)
  return { isLoading, allowedRoutes }
}

export function useMenuRoutes(): { isLoading: boolean; menuRoutes: string[] } {
  const { isLoading, routeConfig } = useCurrentRoleRouteConfig()
  const menuRoutes = Object.entries(routeConfig)
    .filter(([, config]) => config.isAllowed && config.isInMenu)
    .map(([route]) => route)
  return { isLoading, menuRoutes }
}

interface RoleSpecificRouteCompatProps extends RouteProps {
  path: string
}

export const RoleSpecificRouteCompat = ({ path, ...rest }: RoleSpecificRouteCompatProps) => {
  const { allowedRoutes, isLoading: isRouteLoading } = useAllowedRoutes()
  const { isLoading: isAuthLoading } = useAuth()
  // hiding route during loading to prevent fetching data from wrong view when it is not permitted with current role
  // waiting on is auth loading as well because before user role loading, allowed routes is for guest
  return (
    <CompatRoute
      {...rest}
      {...useWrappedRouteProps(rest, isAuthLoading || isRouteLoading, allowedRoutes.includes(path), allowedRoutes[0])}
      path={path}
    />
  )
}

interface RoleSpecificRouteProps extends RouteProps {
  path: string
}

export const RoleSpecificRoute = ({ path, ...rest }: RoleSpecificRouteProps) => {
  const { allowedRoutes, isLoading: isRouteLoading } = useAllowedRoutes()
  const { isLoading: isAuthLoading } = useAuth()
  // hiding route during loading to prevent fetching data from wrong view when it is not permitted with current role
  // waiting on is auth loading as well because before user role loading, allowed routes is for guest
  return (
    <Route
      {...rest}
      {...useWrappedRouteProps(rest, isAuthLoading || isRouteLoading, allowedRoutes.includes(path), allowedRoutes[0])}
      path={path}
    />
  )
}

export const PrivateRoute = (props: RouteProps) => {
  const { data, isLoading } = useCurrentUser()
  const isLoggedIn = !!data?.user
  return <Route {...props} {...useWrappedRouteProps(props, isLoading, isLoggedIn, '/')} />
}

interface RouterRenderWrapper {
  <T extends RouteProps['component'] | RouteProps['children'] | RouteProps['render']>(
    v: T,
    isLoading: boolean,
    isValid: boolean,
    redirectTarget: string,
  ): T | (() => JSX.Element)
}

function useWrappedRouteProps(
  { render, component, children }: RouteProps,
  isLoading: RouterRenderWrapper['arguments']['isLoading'],
  isValid: RouterRenderWrapper['arguments']['isValid'],
  redirectTarget: RouterRenderWrapper['arguments']['redirectTarget'],
) {
  return useMemo(
    () => ({
      component: wrapLoaderAndValidator(component, isLoading, isValid, redirectTarget),
      render: wrapLoaderAndValidator(render, isLoading, isValid, redirectTarget),
      children: wrapLoaderAndValidator(children, isLoading, isValid, redirectTarget),
    }),
    [render, component, children, isLoading, isValid, redirectTarget],
  )
}

const wrapLoaderAndValidator: RouterRenderWrapper = (v, isLoading, isValid, redirectTarget) => {
  return !v ? v : isLoading ? () => <CenterSpinner /> : !isValid ? () => <Redirect to={redirectTarget} /> : v
}
